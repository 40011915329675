// sitesData.js
const sitesData = [
  {
    name: "Oda SKY LAB",
    link: "https://oda.yildizskylab.com",
    logo: "https://avatars.githubusercontent.com/u/96308083?s=280&v=4",
  },
  {
    name: "Yıldız Place",
    link: "https://place.yildizskylab.com",
    logo: "https://place.yildizskylab.com/images/loading.gif",
  },
  {
    name: "Gecekodu",
    link: "https://gecekodu.vercel.app",
    logo: "https://gecekodu-frontend.vercel.app/static/media/gecekodu-logo.58f4d29e83c216b25c5c.png",
  },
  {
    name: "Biz Bize",
    link: "https://bizbize.yildizskylab.com",
    logo: "https://bizbize.yildizskylab.com/Images/bizbize.webp",
  },
  {
    name: "Yıldız Jam",
    link: "https://yildizjam.yildizskylab.com",
    logo: "https://yildizjam.yildizskylab.com/static/media/jam_logo.91d1854517609b06d530.png",
  },
  {
    name: "SKY DAYS",
    link: "https://skydays.yildizskylab.com",
    logo: "https://skydays.yildizskylab.com/static/media/image1.9ea4e4df859cc218a0ca.webp",
  },
  {
    name: "Stant",
    link: "https://stant.yildizskylab.com",
    logo: "https://avatars.githubusercontent.com/u/96308083?s=280&v=4",
  },
];

export default sitesData;

const TeamsData = {
    arge: [
      {
        name: "algolab",
        details: "Algoritma geliştirme, mantıksal ve matematiksel problemlerin optimize çözümleri üzerine çalışan, programlama yarışmalarına katılan, kendilerini competitive-programming alanında geliştiren ekibimiz.",
        logo: "https://stant.yildizskylab.com/static/media/ALGOLAB-LOGO.09661f880769bbb21d9c.png"
      },
      {
        name: "airlab",
        details: "Yapay zekanın çeşitli alt disiplinlerinde araştırmalar yapan; makine öğrenmesi ve derin öğrenme alanlarında çalışan AR-GE ekibidir.",
        logo: "https://stant.yildizskylab.com/static/media/AIR-LAB-LOGO.f4ded04040257f6a03dd.jpg"
      },
      {
        name: "chainlab",
        details: "Blockchain, smart contract, Web1, Web2, Web3, kriptoloji, metaverse, nft gibi alanlarda belirli eğitimleri izleyen ve akran öğrenmesini esas alan, bol bol proje geliştirip her seferinde kendini ileri taşımayı hedefleyen ekibimiz.",
        logo: "https://stant.yildizskylab.com/static/media/chainlab.82417a44e980bab7cc85.jpg"
      },
      {
        name: "gamelab",
        details: "Hem mobil hem de masaüstü oyun geliştiren bunların eğitimini veren, Game Jamlere katılan ve kendi aralarında düzenleyen; Steam, PlayStore gibi çeşitli platformlarda oyunlarını paylaşmayı hedefleyen ekibimiz.",
        logo: "https://stant.yildizskylab.com/static/media/gamelab3.669bcffabebdcd260132.png"
      },
      {
        name: "mobilab",
        details: "Flutter Framework’ü ile cross-platform uygulama geliştiren, bunun eğitimini veren ve projeler geliştirerek bilgilerini pratiğe döken AR-GE ekibidir.",
        logo: "https://stant.yildizskylab.com/static/media/MOBI%CC%87LAB-LOGO.a5dadf679c62829658ea.jpg"
      },
      {
        name: "skysis",
        details: "Gömülü sistemler ve IoT üzerine projeler geliştirmeyi hedefleyen; bu alanda pratik ve teorik eğitimler, atölye çalışmaları ve makale yazma gibi çalışmalar yapmayı amaçlayan ekiptir.",
        logo: "https://stant.yildizskylab.com/static/media/skysis.3a7111c2ebc4faa2634c.jpg"
      },
      {
        name: "skysec",
        details: "Siber güvenlik alanında makale yazma, tool geliştirme ve çeşitli etkinlikler düzenleme üzerine çalışan; aynı zamanda ekip üyelerine bu alanda hem teorik hem de pratik eğitim veren ekibimizdir.",
        logo: "https://stant.yildizskylab.com/static/media/logo.abd1b1cae165a4e15bfe.jpg"
      },
      {
        name: "weblab",
        details: "Hem Front End, hem de Back End alanında araştırmalar yapan, eğitimler düzenleyen, projeler üreterek kendilerini geliştiren AR-GE ekibidir.",
        logo: "https://stant.yildizskylab.com/static/media/WEBLAB-LOGO.31b2666aff0a40aeaa3f.jpg"
      }
    ],
    social: [
        {
            "name": "organizasyon",
            "logo": "SkylabPinkLogo.png",
            "details": "Organizasyon ekibi olarak sene içerisinde ARTLAB : Yapay Zeka Zirvesi, SKYDAYS : Türkiye'nin En Büyük Siber Güvenlik Zirvesi, YILDIZ JAM : Oyun Geliştirme Zirvesi ve Yarışması olmak üzere üç büyük organizasyon düzenliyoruz. Bunların yanı sıra mezunlarımızla buluştuğumuz, iş hayatını tanımamızda ve bağlantılar kurmamızda yardımcı olan söyleşi temalı BİZBİZE etkinliğimiz ve sosyal sorumluluklarımızı yerine getirmemizi sağlayan etkinliklerimizle hem kulübümüze hem üniversitemize sosyal ve akademik anlamda değer sağlamayı amaçlıyoruz."
          },
          {
            "name": "sosyal sorumluluk",
            "logo": "SkylabPinkLogo.png",
            "details": "Sosyal sorumluluk projelerine katılmak isteyenleri bir araya getiriyoruz ve Sosyal Sorumluluk ekibi olarak çeşitli yardım etkinlikleri ve projeleri koordine ediyoruz."
          },
          {
            "name": "sky media",
            "logo": "SkylabPinkLogo.png",
            "details": "Grafik tasarım, animasyon ve video düzenleme gibi alanlarda eğitimler ve çalışmalar yürütüp kulübümüzün görsel kimliğini oluşturmak, etkileyici medya içerikleri üretmek ve kulübümüzün hikayesini anlatmak için çalışıyoruz"
          },
          {
            "name": "gecekodu",
            "logo": "SkylabPinkLogo.png",
            "details": "Haftanın bir günü toplanarak sabaha kadar ilgi alanlarımıza bağlı olarak zaman zaman ekipler halinde zaman zaman bireysel çalışmalar yapıyoruz. Farklı alanlarda Workshop veriyor ve gruplar halinde veya bireysel çalışmalarla projeler geliştiriyoruz."
          },
          {
            "name": "socilab",
            "logo": "SkylabPinkLogo.png",
            "details": "Sosyalleşme ve etkileşim konusunda yaratıcı fikirlerle Socilab ekibi olarak kulüp üyelerinin keyifli vakit geçirebileceği etkinlikler düzenlemek ve kulüp içi bağları güçlendirmek için çalışıyoruz."
          }
    ]
  };
  
  export default TeamsData;
  